const Animations = {
  idle: {
    wrapper: {},
    delivery: {
      clip: 'DeliveryMethod_idle',
      loop: 'repeat',
      timeScale: 2,
    },
  },
  pop: {
    wrapper: {
      clip: 'None_pop',
      timeScale: 1,
      loop: 'once',
      clampWhenFinished: 'true',
    },
    delivery: {
      clip: 'DeliveryMethod_pop',
      timeScale: 1,
      loop: 'once',
      clampWhenFinished: 'true',
    },
    offr: {
      clip: 'pop',
      timeScale: 1,
      loop: 'once',
      clampWhenFinished: 'true',
    },
    cont: {
      clip: 'pop',
      timeScale: 1,
      loop: 'once',
      clampWhenFinished: 'true',
    },
  },
  pop_delivery: {
    wrapper: {
      clip: 'DeliveryMethod_pop',
      timeScale: 1,
      loop: 'once',
      clampWhenFinished: 'true',
    },
  },
};

const Sounds = {
  pop: {
    wrapper: {
      autoplay: true,
    },
  },
};

const prizeURL = 'https://eberko25.wixsite.com/golden';
const prizeTimeout = 1000000;
const defaultCompanyLogoURL =
  'https://nudgear.s3.us-east-2.amazonaws.com/ArAssests/textures/Nudge_logo.png';
const CustomWrapperAttacher = 'gift_voucher_cnt';

export const NUDGE_CONSTANTS = {
  Animations,
  Sounds,
  prizeURL,
  defaultCompanyLogoURL,
  CustomWrapperAttacher,
  prizeTimeout,
};
