import * as THREE from 'three';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('mark', {
  init: function () {
    setTimeout((e) => {
      let mesh = this.el.getObject3D('mesh');
      var bbox = new THREE.Box3().setFromObject(this.el.object3D);

      mesh.position.set((bbox.min.x - bbox.max.x) / 2, 0, 0);
    }, 1000);
  },
});
