import React, { useState } from 'react';
import './QuickStartScreen.css';

function QuickStartScreen(props) {
  const { color_code, hide } = props;

  const [screenSmallerThan1024, setScreenSmallerThan1024] = useState(window.screen.width <= 1024);

  const getStyle = () => {
    return {
      backgroundColor: color_code,
      display: screenSmallerThan1024 ? 'flex' : 'absolute',
    };
  };

  return (
    <div id="quick_start" style={getStyle()}>
      <div>
        <img src="https://nudgear.s3.us-east-2.amazonaws.com/grid.png" alt=""></img>
        <span>
          {' '}
          Point camera at
          <br /> any open space
        </span>
      </div>
      <div>
        <img src="https://nudgear.s3.us-east-2.amazonaws.com/360-degrees.png" alt=""></img>
        <span>
          {' '}
          View your gift <br />
          from any angle
        </span>
      </div>
      <div>
        <img src="https://nudgear.s3.us-east-2.amazonaws.com/tap.png" alt=""></img>
        <span>
          {' '}
          Tap gift box to
          <br /> reveal your gift
        </span>
      </div>
      <div>
        <button id="hide" style={{ backgroundColor: color_code }} onClick={hide}>
          Let's go!
        </button>
      </div>
    </div>
  );
}

export default QuickStartScreen;
