import { delayedWindowURLChange } from '../../../utils/utils';
import { NUDGE_CONSTANTS } from '../../../constants/constants';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('next-button-nudge', {
  schema: {
    deliveryMethodId: { type: 'boolean', default: false },
    mp3Path: { type: 'string', default: '' },
  },
  active: false,
  activate: function () {
    const { deliveryMethodId, mp3Path } = this.data;
    const wrapper = document.getElementById('wrapper');
    const delivery = document.getElementById('delivery');
    const offr = document.getElementById('offr');
    const cont = document.getElementById('cont');

    const Animation = () => {
      if (wrapper) {
        wrapper.setAttribute('sound', `src: url( ${mp3Path}); autoplay: true`);

        wrapper.setAttribute('animation-mixer', {
          // clip: deliveryMethodId ? 'DeliveryMethod_pop' : 'None_pop',
          clip: 'pop',
          timeScale: 0.5,
          loop: 'once',
          clampWhenFinished: 'true',
        });
      }

      if (delivery) {
        delivery.setAttribute('animation-mixer', {
          clip: 'DeliveryMethod_pop',
          timeScale: 1,
          loop: 'once',
          clampWhenFinished: 'true',
        });
      }

      if (offr) {
        offr.setAttribute('animation-mixer', {
          clip: 'pop',
          timeScale: 1,
          loop: 'once',
          clampWhenFinished: 'true',
        });
      }

      if (cont) {
        cont.setAttribute('animation-mixer', {
          clip: 'pop',
          timeScale: 1,
          loop: 'once',
          clampWhenFinished: 'true',
        });
      }

      const newElement = document.createElement('a-entity');
      newElement.setAttribute('position', wrapper.getAttribute('position'));
      newElement.setAttribute('shadow', true);
      newElement.setAttribute(
        'particle-system',
        'size:0.4;particleCount:100;accelerationSpread: 1 0 1; velocitySpread: 0.75 0.5 0.75; accelerationValue: -.02 -.6 -.02; velocityValue:0 2 0;blending:1;texture: /logo.png ;color:#70D4CA,#0B766C ',
      );
      wrapper.sceneEl.appendChild(newElement);

      delayedWindowURLChange(NUDGE_CONSTANTS.prizeURL, NUDGE_CONSTANTS.prizeTimeout);
    };

    if (wrapper) {
      wrapper.onclick = Animation;
      this.active = true;
    }
    if (delivery) {
      delivery.onclick = Animation;
      this.active = true;
    }
    // let modeName = delivery.getAttribute("gltf-model");
  },
  tick: function () {
    if (!this.active) {
      this.activate();
    }
  },
});
