import { createMachine, assign, interpret } from 'xstate';

import { inspect } from '@xstate/inspect';
import { apiService } from '../services/apiService';

inspect({
  // options
  // url: 'https://statecharts.io/inspect', // (default)
  iframe: false, // open in new window
});

const actionTypes = {
  SET_EXPERIENCE_AND_DB: 'SET_EXPERIENCE_AND_DB',
  CLOSE_INSTRUCTIONS: 'CLOSE_INSTRUCTIONS',
  ERROR: 'ERROR',
  PRELOAD_FINISH: 'PRELOAD_FINISH',
  DELIVERY_SELECTED: 'DELIVERY_SELECTED',
  INTRO_FINISHED: 'INTRO_FINISHED',
  FINISH_UNWRAP: 'FINISH_UNWRAP',
};

const experienceActiveStates = {
  initial: 'preloading',
  states: {
    preloading: {
      on: {
        [actionTypes.PRELOAD_FINISH]: [
          {
            target: 'selectDelivery',
            actions: ['showManualDeliveryUI'],
            cond: 'isManualDeliveryPoint',
          },
          {
            target: 'startExperience',
            cond: 'isAutoDeliveryPoint',
          },
        ],
      },
    },
    selectDelivery: {
      entry: ['showDeliverySelectUI', 'listenForDelivery'],
      on: {
        [actionTypes.DELIVERY_SELECTED]: {
          actions: [
            'updateDeliveryCoordinate',
            // 'stopListeningForDelivery',
            // 'hideDeliverySelectUI'
          ],
          target: 'startExperience',
        },
      },
    },
    startExperience: {
      entry: ['startExperience'],
      on: {
        [actionTypes.INTRO_FINISHED]: {
          target: 'waitForInteraction',
        },
      },
    },
    waitForInteraction: {
      entry: ['waitForTaps'],
      on: {
        TAP: {
          target: 'animateOpening',
        },
      },
    },
    animateOpening: {
      entry: ['showUnwrapAnimations'],
      on: {
        [actionTypes.FINISH_UNWRAP]: {
          target: 'redirectToOffer',
        },
      },
    },
    redirectToOffer: {
      entry: ['redirectToOffer'],
    },
  },
};

const nudgeExperienceMachine = createMachine(
  {
    id: 'nudge-experience-machine',
    initial: 'idle',
    context: {
      debug: true,
      experienceData: null,
      dbId: null,
      experienceId: null,
      deliveryPoint: 'manual',
      customModelURL: 'https://assets.nudge.solidsolutions.pt/custom/coffeecup_voucher.glb',
      centerPoint: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    states: {
      idle: {
        on: {
          [actionTypes.SET_EXPERIENCE_AND_DB]: {
            target: 'gettingExperienceData',
            actions: ['saveExperienceAndDB'],
          },
        },
      },
      gettingExperienceData: {
        invoke: {
          src: 'getExperienceData',
          onDone: {
            target: 'selectingNextPage',
            actions: ['saveExperienceData'],
          },
          onError: { target: 'error' },
        },

        // entry: ['getExperienceData'],
        // on: {
        //   '': [
        //     {
        //       target: 'error',
        //       cond: 'isErrorPage',
        //     },
        //     {
        //       target: 'instructionsScreen',
        //       cond: 'isExperience',
        //     },
        //   ],
        // },
      },
      selectingNextPage: {
        on: {
          '': [
            {
              target: 'error',
              cond: 'isErrorPage',
            },
            {
              target: 'instructionsScreen',
              cond: 'isExperience',
            },
          ],
        },
      },
      error: {
        // entry: ['removeActiveModeListeners', 'hideCarpets', 'restoreScene'],
        type: 'final', //TODO: types of final states?
      },
      instructionsScreen: {
        entry: ['showInstructions'],
        exit: ['closeInstructions'],
        on: {
          [actionTypes.CLOSE_INSTRUCTIONS]: {
            target: 'experience',
          },
        },
      },
      experience: {
        entry: ['preloadAssets', 'initAframeStateMAchine'],
        // exit: ['removeActiveModeListeners', 'hideCarpets', 'restoreScene'],
        on: {
          [actionTypes.ERROR]: 'error',
        },
        ...experienceActiveStates,
      },
    },
  },
  {
    services: {
      getExperienceData: (context, event) => {
        console.log('starting getExperienceData', context, event);
        return apiService.getExperienceData(context.dbId, context.experienceId);
      },
    },
    actions: {
      saveExperienceAndDB: assign((context, event) => {
        console.log('saveExperienceAndDB', context, event);
        return {
          dbId: event.dbId,
          experienceId: event.experienceId,
        };
      }),
      saveExperienceData: assign((context, event) => {
        console.log('saveExperienceAndDB', context, event);
        const data = event.data;
        if (context.customModelURL) {
          data.experience.customModelURL = data.experience.customModelURL || context.customModelURL;
        }
        return {
          data: event.data,
        };
      }),
      updateDeliveryCoordinate: assign((context, event) => {
        console.log('updateDeliveryCoordinate', context, event);
        return {
          centerPoint: event.point,
        };
      }),
    },
    guards: {
      isErrorPage: (context) => {
        return context.data.type === 'techError';
      },
      isExperience: (context) => {
        return context.data.type === 'experience';
      },
      isManualDeliveryPoint: (context) => {
        return context.deliveryPoint === 'manual' || context.data.deliveryPoint === 'manual';
      },
      isAutoDeliveryPoint: (context) => {
        return !context.data.deliveryPoint || context.data.deliveryPoint === 'auto';
      },
    },
  },
);

const machine = {
  service: interpret(nudgeExperienceMachine, {
    devTools: true,
  }),
};

machine.service.onTransition((state) => {
  machine.state = state;
  if (state.context.debug && state.changed) {
    console.log('NudgeMachine transition', state.value, state._event.name, state, machine.service);
  }
});

const TOUCH_MOUSE_MAP = {
  touchstart: 'mousedown',
  touchmove: 'mousemove',
  touchend: 'mouseup',
};

const mapMouseEventToSend = (event) => {
  const _event = {
    type: event.type,
    x: event.clientX,
    y: event.clientY,
  };
  if (event.type.includes('touch')) {
    _event.type = TOUCH_MOUSE_MAP[event.type];
    const evt = event.originalEvent || event;
    const touch = evt.touches[0] || evt.changedTouches[0];
    _event.x = touch.clientX;
    _event.y = touch.clientY;
  }
  const rect = FurnitureCarpetManagerMachine.state.context.viewer.canvas.getBoundingClientRect(),
    x = _event.x - rect.left,
    y = _event.y - rect.top;
  _event.x = (x / FurnitureCarpetManagerMachine.state.context.viewer.canvas.clientWidth) * 2 - 1;
  _event.y = -(y / FurnitureCarpetManagerMachine.state.context.viewer.canvas.clientHeight) * 2 + 1;
  _event.originalEvent = event;
  // console.log(_event,event,event.target);
  machine.service.send(_event);
};

machine.actionTypes = actionTypes;

const setInactive = (event) => {
  machine.service.send('SET_INACTIVE');
};

machine.service.start();

window.experienceMachine = machine;
export default machine;
