import * as THREE from 'three';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('remove-glb-lights', {
  schema: {},
  init: function () {
    // Wait for model to load.
    this.el.addEventListener('model-loaded', () => {
      // Grab the mesh / scene.
      // Go over the submeshes and modify materials we want.
      console.log('remove-glb-lights object3D', this.el.object3D);
      const toRemove = [];
      this.el.object3D.traverse((node) => {
        // TODO: check if possible to export ribbon material settings for roughness and metalness
        if (node.parent) {
          const parent = node.parent;
          if (parent.name.includes('Light')) {
            console.log('remove-glb-lights', node, parent);
            toRemove.push(parent);
          }
        }
      });
      toRemove.forEach((light) => light.parent.remove(light));
    });
  },
});
