const AFRAME = window.AFRAME;

AFRAME.registerComponent('make-materials-reflective', {
  schema: {},
  init: function () {
    this.el.object3D.traverse((node) => {
      if (node.material) {
        console.log('wrapper', obj, node.material, node.name);
        node.material.roughness = 0.5;
        node.material.metalness = 0.5;
      }
    });
  },
});
