AFRAME.registerComponent('shadow-material', {
  init() {
    this.material = new THREE.ShadowMaterial();
    const mesh = this.el.getOrCreateObject3D('mesh');
    console.log('shadow-material', mesh);
    mesh.material = this.material;
    mesh.receiveShadow = true;
    // this.material.opacity = 0.3;
  },
});
