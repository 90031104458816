import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './components/react/ui/App';
import NudgeMain from './components/react/NudgeMain';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/:dbId/:experienceId">
          <NudgeMain key="NudgeMain" />
        </Route>
        <Route path="/">
          <App key="app" />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
