import React from 'react';
import PropTypes from 'prop-types';

const EstimatedLight = (props) => {
  const centerPoint = props.centerPoint;

  const defaultPosition = [5, 5, 2];
  const position = Object.values(centerPoint)
    .map((value, index) => {
      return value + defaultPosition[index];
    })
    .join(' ');
  return (
    <a-entity
      xr-light={'min:0;max:2'}
      shadow
      light="
      type: directional;
      intensity: 1;
      castShadow: true;
   shadowMapHeight:2048;
      shadowMapWidth:2048;
      shadowCameraNear: -5;
      shadowCameraRight: 25;
      shadowCameraLeft: -25;
      shadowCameraTop: 25;
      shadowCameraBottom: -25;
      target: #wrapper;

      "
      xrextras-attach="target: #wrapper; offset: 5 2 5;"
      position="5 5 5"
    ></a-entity>
  );
};

EstimatedLight.propTypes = {};

export default EstimatedLight;
