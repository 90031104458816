import * as THREE from 'three';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('delivery', {
  schema: {
    deliveryMColor: { type: 'string', default: '' },
    deliveryMTexturePath: { type: 'string', default: '' },
  },
  init: function () {
    const { deliveryMColor, deliveryMTexturePath } = this.data;
    console.log('delivery!', this.data);
    // Wait for model to load.
    this.el.addEventListener('model-loaded', () => {
      console.log('delivery', this.data, this.el.getObject3D('mesh'), this.el);
      // Grab the mesh / scene.
      const obj = this.el.getObject3D('mesh');
      // Go over the submeshes and modify materials we want.
      obj.traverse((node) => {
        console.log(node.name, node.type);
        if (node.material) {
          console.log('wrapper', obj, node.material, node.name);
          //move this to json with settings
          if (node.material.name === 'HotAirBaloon_Mat_White') {
            node.material.roughness = 0.5;
            node.material.metalness = 0.5;
          }
          if (node.material.name === 'HotAirBaloon_Mat_Red') {
            node.material.roughness = 0.2;
            node.material.metalness = 0.8;
          }
        }
        if (node.name.slice(-2) === '_0') {
          node.material.color.set(deliveryMColor);
        }

        //      if (modeName === 'HotAirBalloon_gifti.glb') {
        //   if (node.name.substr(node.name.length-2,node.name.length-2) === '_2') {
        //     node.material.color.set('');
        //   }
        // }
        else {
          if (node.name.slice(-2) === '_2' && deliveryMTexturePath !== '') {
            var tex = new THREE.TextureLoader().load(deliveryMTexturePath);
            node.material = new THREE.MeshPhysicalMaterial({
              map: tex,
              alphaTest: 0.5,
              reflectivity: 1,
              skinning: true,
              clearcoat: 1,
              clearcoatRoughness: 0,
            });
            tex.wrapS = THREE.MirroredRepeatWrapping;
            tex.wrapT = THREE.MirroredRepeatWrapping;
          }
        }
      });
    });
  },
});
