import React from 'react';
import PropTypes from 'prop-types';

const Ground = (props) => {
  return (
    <a-box
      id="ground"
      class="cantap"
      scale="1000 2 1000"
      position="0 -1 0"
      material="shader: shadow; transparent: true; opacity: 0.4"
      shadow
    ></a-box>
  );
};

Ground.propTypes = {};

export default Ground;
