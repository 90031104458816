import React from 'react';
import PropTypes from 'prop-types';
import './PlaceDeliveryPoint.css';

import experienceMachine from '../../../../machines/experienceMachine';
import { useService } from '@xstate/react';
import Ground from '../prefabs/Ground';

const PlaceDeliveryPoint = (props) => {
  const [state, send] = useService(experienceMachine.service);
  const selectedDelivery = (point) => {
    send(experienceMachine.actions.DELIVERY_SELECTED, { point });
  };
  return (
    <>
      <div key={'PlaceDeliveryPoint'} className={'place-delivery'}>
        {' '}
        Tap on a surface to select the drop zone for your gift
        <div className={'place-delivery-img-container'}>
          <img src="https://nudgear.s3.us-east-2.amazonaws.com/tap.png" alt=""></img>
        </div>
      </div>

      <Ground />
    </>
  );
};

PlaceDeliveryPoint.propTypes = {};

export default PlaceDeliveryPoint;
