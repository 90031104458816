// TODO: remove dependency
import experienceMachine from '../../../machines/experienceMachine';

const tapPlaceComponent = {
  init() {
    const ground = document.getElementById('ground');
    console.log('ground', ground);
    ground.addEventListener('click', (event) => {
      // alert('click');
      console.error('click', ground);
      const data = experienceMachine.state.context.data;
      const wrapperId = data.wrapperId;
      // Create new entity for the new object
      const newElement = document.createElement('a-entity');
      // The raycaster gives a location of the touch in the scene
      const touchPoint = event.detail.intersection.point;
      experienceMachine.service.send(experienceMachine.actionTypes.DELIVERY_SELECTED, {
        point: touchPoint,
      });
      // newElement.setAttribute('position', touchPoint);
      // const randomYRotation = Math.random() * 360;
      // newElement.setAttribute('rotation', `0 ${randomYRotation} 0`);
      // newElement.setAttribute('visible', 'false');
      // newElement.setAttribute('scale', '0.0001 0.0001 0.0001');
      // newElement.setAttribute('shadow', {
      //   receive: false,
      // });
      // // newElement.setAttribute('gltf-model', '#wrapperGLB');
      // newElement.setAttribute('gltf-model', `url(${wrapperId ? wrapperId.glb : ''})`);
      // this.el.sceneEl.appendChild(newElement);
      // newElement.addEventListener('model-loaded', () => {
      //   // Once the model is loaded, we are ready to show it popping in using an animation
      //   newElement.setAttribute('visible', 'true');
      //   newElement.setAttribute('animation', {
      //     property: 'scale',
      //     to: '1 1 1',
      //     easing: 'easeOutElastic',
      //     dur: 800,
      //   });
      // });
    });
  },
};

AFRAME.registerComponent('tap-place', tapPlaceComponent);
