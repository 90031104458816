export const apiService = {
  getExperienceData: (dbId, experienceId) => {
    console.log('getExperienceData', process.env, dbId, ' ', experienceId);
    return fetch(`${process.env.REACT_APP_API_URL}${dbId}/${experienceId}`)
      .then((res) => res.json())
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);
        return data;
      })
      .catch(function (error) {
        console.log('Request failed', error);
        return { type: 'error' };
      });
  },
};
