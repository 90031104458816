import React, { useEffect, useState } from 'react';
import './TechError.css';

function TechError(props) {
  const { data } = props;

  useEffect(() => {
    document.body.style.backgroundColor = data.color_code;
  }, [data]);

  return (
    <>
      <div>
        <p className="heading">{data.msg}</p>
        <p className="msg">{data.msg_dec}</p>
      </div>
      <div>
        <img src={data.img_url} alt="" style={{ width: '250px' }} />
        <p></p>
      </div>
    </>
  );
}

export default TechError;
