import React from 'react';
import PropTypes from 'prop-types';

Delivery.propTypes = {
  deliveryMethodId: PropTypes.object.isRequired,
  deliveryMTextureId: PropTypes.string,
  deliveryMColor: PropTypes.string,
};

function Delivery(props) {
  const { deliveryMethodId, deliveryMTextureId, deliveryMColor, centerPoint } = props;
  const position = Object.values(centerPoint).join(' ');
  return deliveryMethodId ? (
    <a-entity
      id="delivery"
      class="cantap"
      teste={'rui'}
      position={`${position}`}
      // rotation={'90 0 0'}
      cubemap-realtime
      remove-glb-lights
      // gltf-model={`url(${
      //     wrapperId ? wrapperId.glb : ""
      // })`}
      // gltf-model={`url(${deliveryMethodId.modelPath})`}
      gltf-model="#deliveryGLB"
      animation-mixer="clip:DeliveryMethod_idle; loop:repeat; timeScale: 2; "
      shadow={'cast:true;'}
      // scale={'0.01 0.01 0.01'}
      delivery={`
              ${
                deliveryMTextureId ? 'deliveryMTexturePath:' + deliveryMTextureId.texturePath : ''
              }; 
             ${deliveryMColor ? 'deliveryMColor:' + deliveryMColor : ''}; 
          `}
    />
  ) : null;
}

export default Delivery;
