import React, { useEffect, useState } from 'react';
// import AFRAME from "aframe";
// import { Scene } from "aframe-react";

import '../../aframe';
import Lights from './prefabs/Lights';
import QuickStartScreen from '../ui/QuickStartScreen';
import Wrapper from './prefabs/Wrapper';
import Delivery from './prefabs/Delivery';
import Ground from './prefabs/Ground';
import ClassicExperience from './experiences/ClassicExperience';
import { NUDGE_CONSTANTS } from '../../../constants/constants';
import PlaceDeliveryPoint from './deliverySystems/PlaceDeliveryPoint';
import EstimatedLight from './prefabs/EstimatedLight';

const AFRAME = window.AFRAME;
function ARScene(props) {
  const { data, setPreloadComplete, state, centerPoint } = props;

  const { experience, admin, color_code } = data;
  //TODO: have substate machines for different experiences and make that select the current experience
  const [started, setStarted] = useState(false);
  useEffect(() => {
    if (state === 'startExperience') {
      setStarted(true);
    }
  }, [state]);

  useEffect(() => {
    document.getElementById('preloader').addEventListener(
      'loaded',
      function (model, second) {
        // console.log('wrapperGLB preloader', model, second);
        setPreloadComplete();
      },
      [],
    );

    // document
    //   .getElementById('wrapperGLB')
    //   .addEventListener('model-loaded', function (model, second) {
    //     console.log('wrapperGLB model-loaded', model, second);
    //   });
    // document.getElementById('wrapperGLB').addEventListener('model-error', function (model, second) {
    //   console.log('wrapperGLB model-error', model, second);
    // });
    // console.log('wrapperGLB', experience.wrapperId.glb, experience.deliveryMethodId.modelPath);
  }, []);

  // TODO: what does this do? sets some geometry size
  // function bBack() {
  //   setTimeout(() => {
  //     var texGeo = document.getElementById("TexGeo");
  //     var boxWid = document.getElementById("BoxWid");
  //     if (texGeo && boxWid) {
  //       var tValue = texGeo.getAttribute("text-geometry");
  //       var len = tValue.value.length;
  //       boxWid.setAttribute("geometry", { width: len / 3 });
  //     }
  //   }, 1000);
  // }
  //
  // window.bBack = bBack;
  //
  // useEffect(() => {
  //   bBack();
  // }, []);

  // TODO: what is this
  // changes default message from 8th wall
  // let inDom = false;
  // const observer = new MutationObserver(() => {
  //   if (document.querySelector(".prompt-box-8w")) {
  //     if (!inDom) {
  //       document.querySelector(".prompt-box-8w p").innerHTML =
  //         "<strong>“Nudge”</strong><br/><br/>Would Like to Access the Camera";
  //       document.querySelector(".prompt-button-8w").innerHTML = "Deny";
  //       document.querySelector(".button-primary-8w").innerHTML = "Approve";
  //     }
  //     inDom = true;
  //   } else if (inDom) {
  //     inDom = false;
  //     observer.disconnect();
  //   }
  // });
  // observer.observe(document.body, { childList: true });

  // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
  const {
    wrapperId,
    mediaId,
    deliveryMethodId,
    wrapTextureId,
    scaling,
    customModelURL,
  } = experience;
  const mp3Path = mediaId ? mediaId.mp3Path : undefined;
  return (
    <div>
      <a-scene
        xrweb
        next-button-nudge={`
        deliveryMethodId:${deliveryMethodId};
        mp3Path:${mp3Path}
        `}
        tap-place
        next-button
        gesture-detector
        xrextras-almost-there
        stats
        shadow
        light="defaultLightsEnabled: false"
        xrextras-loading="
                            loadBackgroundColor: #F5F5DC;
                            cameraBackgroundColor: #E1C699;
                            loadImage: #myCustomImage;
                            loadAnimation: scale"
        xrextras-runtime-error
        renderer
      >
        <a-assets id="preloader">
          <a-asset-item
            id="customGLB"
            src={`${customModelURL ? customModelURL : ''}`}
          ></a-asset-item>
          <a-asset-item id="wrapperGLB" src={`${wrapperId ? wrapperId.glb : ''}`}></a-asset-item>
          <a-asset-item
            id="deliveryGLB"
            src={`${deliveryMethodId ? deliveryMethodId.modelPath : ''}`}
          ></a-asset-item>
          <img
            id="myCustomImage"
            crossOrigin="anonymous"
            src={
              admin.companyLogoUrl ? admin.companyLogoUrl : NUDGE_CONSTANTS.defaultCompanyLogoURL
            }
            alt=""
          ></img>
        </a-assets>
        {state !== 'preloading' ? (
          <>
            <a-camera
              id="camera"
              position="0 3 3"
              raycaster="objects: .cantap, .mark"
              cursor="fuse: false; rayOrigin: mouse;"
            ></a-camera>

            {/*<Lights />*/}
            <EstimatedLight centerPoint={centerPoint} />

            {started ? (
              <ClassicExperience experienceData={experience} centerPoint={centerPoint} />
            ) : null}
            {state === 'selectDelivery' ? <PlaceDeliveryPoint /> : null}
          </>
        ) : null}
      </a-scene>
    </div>
  );
}

export default ARScene;
