import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../prefabs/Wrapper';
import Delivery from '../prefabs/Delivery';

const ClassicExperience = (props) => {
  const { centerPoint, experienceData } = props;
  const {
    wrapperId,
    contentTextureUrl,
    mediaId,
    deliveryMTextureId,
    deliveryMColor,
    deliveryMethodId,
    wrapTextureId,
    wrapperPrimaryColor,
    wrapperSecondaryColor,
    scaling,
    animationId,
    textId,
    buttonCtaId,
    contentId,
  } = experienceData;
  const texturePath = wrapTextureId ? wrapTextureId.texturePath : undefined;
  const experienceScale = scaling || 1;
  return (
    <>
      <Wrapper
        wrapperId={wrapperId}
        deliveryMethodId={deliveryMethodId}
        texturePath={texturePath}
        wrapperPrimaryColor={wrapperPrimaryColor}
        wrapperSecondaryColor={wrapperSecondaryColor}
        centerPoint={centerPoint}
      />

      <Delivery
        deliveryMethodId={deliveryMethodId}
        deliveryMTextureId={deliveryMTextureId}
        deliveryMColor={deliveryMColor}
        centerPoint={centerPoint}
      />

      {/*<a-entity*/}
      {/*  id="offr"*/}
      {/*  class="cantap"*/}
      {/*  position="0 4 0"*/}
      {/*  gltf-model={`url(${contentId ? contentId.contentPath : ''})`}*/}
      {/*  scale={`${experienceScale * 0.0007} ${experienceScale * 0.0007} ${*/}
      {/*    experienceScale * 0.0007*/}
      {/*  }`}*/}
      {/*  animation-mixer="clip:idle; loop:repeat; timeScale: 2; "*/}
      {/*  content={`${contentTextureUrl ? 'contentTextureUrl:' + contentTextureUrl : ''};`}*/}
      {/*></a-entity>*/}

      {/*<a-entity*/}
      {/*  id="cont"*/}
      {/*  class="cantap"*/}
      {/*  position="0 4 0"*/}
      {/*  gltf-model={`url(${animationId ? animationId.animationPath : ''})`}*/}
      {/*  scale={`${experienceScale * 0.0008} ${experienceScale * 0.0008} ${*/}
      {/*    experienceScale * 0.0008*/}
      {/*  }`}*/}
      {/*  animation-mixer="clip:idle; loop:repeat; timeScale: 2; "*/}
      {/*></a-entity>*/}

      {/*{textId ? (*/}
      {/*  <a-entity*/}
      {/*    position="8 6 -2"*/}
      {/*    text="value: <%= experience.textId.textContent %>;*/}
      {/*           color: #fff000;*/}
      {/*           width: 15 ;*/}
      {/*           side: double"*/}
      {/*  ></a-entity>*/}
      {/*) : null}*/}

      {/*{buttonCtaId ? (*/}
      {/*  <>*/}
      {/*    {' '}*/}
      {/*    <a-entity*/}
      {/*      class="mark"*/}
      {/*      id="TexGeo"*/}
      {/*      link="href: <%= experience.buttonCtaId.btnLink %>"*/}
      {/*      position="0 2.85 0"*/}
      {/*      material="color: <%= experience.buttonCtaId.txtColor %>;"*/}
      {/*      text-geometry="value: <%= experience.buttonCtaId.btnText %>; size: 0.4; height: 0.15;"*/}
      {/*      mark*/}
      {/*      animation="property: rotation; to: 0 0.1 0; loop:true; easing: linear"*/}
      {/*    ></a-entity>*/}
      {/*    <a-box*/}
      {/*      class="mark"*/}
      {/*      link="href: <%= experience.buttonCtaId.btnLink %>"*/}
      {/*      id="BoxWid"*/}
      {/*      width="1.2"*/}
      {/*      height="1"*/}
      {/*      depth="0.20"*/}
      {/*      position="0 3 0"*/}
      {/*      material="color: <%= experience.buttonCtaId.btnColor %>;"*/}
      {/*    ></a-box>*/}
      {/*  </>*/}
      {/*) : null}*/}
    </>
  );
};

ClassicExperience.propTypes = {
  experienceData: PropTypes.object.isRequired,
};

export default ClassicExperience;
