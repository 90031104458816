import React, { useEffect, useState } from 'react';
import { useService } from '@xstate/react';
import { useParams } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import TechError from './ui/TechError';
import ARScene from './nudgeAR/ARScene';
import experienceMachine from './../../machines/experienceMachine';
import QuickStartScreen from './ui/QuickStartScreen';
if (process.env.NODE_ENV === 'development') {
  import('../../style/development.css');
}

function NudgeMain() {
  let { dbId, experienceId } = useParams();

  const [state, send] = useService(experienceMachine.service);

  useEffect(() => {
    send(experienceMachine.actionTypes.SET_EXPERIENCE_AND_DB, { dbId, experienceId });
  }, [dbId, experienceId]);

  const data = state.context && state.context.data;
  const centerPoint = state.context && state.context.centerPoint;
  const hideInstructions = () => {
    send(experienceMachine.actionTypes.CLOSE_INSTRUCTIONS);
  };

  const setPreloadComplete = () => {
    send(experienceMachine.actionTypes.PRELOAD_FINISH);
  };

  return (
    <h3>
      {state.value === 'instructionsScreen' ? (
        <QuickStartScreen color_code={data.color_code} hide={hideInstructions} />
      ) : null}
      {state.value === 'error' ? <TechError key="TechError" data={data} /> : null}
      {state.value.experience ? (
        <ARScene
          key="ar-scene"
          data={data}
          centerPoint={centerPoint}
          setPreloadComplete={setPreloadComplete}
          state={state.value.experience}
        />
      ) : null}
    </h3>
  );
}

export default NudgeMain;
