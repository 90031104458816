import React from 'react';
import PropTypes from 'prop-types';
import { NUDGE_CONSTANTS } from '../../../../constants/constants';

Wrapper.propTypes = {
  wrapperId: PropTypes.object.isRequired,
  deliveryMethodId: PropTypes.object,
  texturePath: PropTypes.string,
  wrapperPrimaryColor: PropTypes.string,
  wrapperSecondaryColor: PropTypes.string,
};

function Wrapper(props) {
  const {
    wrapperId,
    deliveryMethodId,
    texturePath,
    wrapperPrimaryColor,
    wrapperSecondaryColor,
    centerPoint,
  } = props;
  const position = Object.values(centerPoint).join(' ');

  const groundRootPosition = [0, -0.01, 0];
  const groundFinalPosition = Object.values(centerPoint)
    .map((pointValue, index) => {
      return pointValue + groundRootPosition[index];
    })
    .join(' ');
  console.log('wrapper', groundFinalPosition);
  return wrapperId ? (
    <>
      <a-entity
        id="wrapper"
        class="cantap"
        position={`${position}`}
        // gltf-model={`url(${wrapperId ? wrapperId.glb : ''})`}
        gltf-model="#wrapperGLB"
        // gltf-model={`url(${wrapperId.glb})`}
        // gltf-model="#wrapperGLB"
        cubemap-realtime
        attach-glb-to-attacher={`glbId:customGLB; attacher:${NUDGE_CONSTANTS.CustomWrapperAttacher}`}
        remove-glb-lights
        animation-mixer={`clip:${
          // deliveryMethodId ? 'DeliveryMethod_idle' : 'idle'
          'idle'
        }; 
      loop:repeat; 
      // timeScale: 2;
       `}
        wrapper={`
             ${texturePath ? 'texturePath:' + texturePath : ''}; 
             ${wrapperPrimaryColor ? 'wrapperPrimaryColor:' + wrapperPrimaryColor : ''}; 
             ${wrapperSecondaryColor ? 'wrapperSecondaryColor:' + wrapperSecondaryColor : ''}; 
          `}
        shadow={'cast:true;receive:true;'}
      ></a-entity>

      <a-plane
        rotation="-90 0 0"
        color="#FFF"
        position={groundFinalPosition}
        height="20"
        width="20"
        shadow-material
      ></a-plane>
    </>
  ) : null;
}

export default Wrapper;
