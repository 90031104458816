import * as THREE from 'three';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('wrapper', {
  schema: {
    texturePath: {
      type: 'string',
      default:
        'https://nudgear.s3.us-east-2.amazonaws.com/ArAssests/textures/TreasureChest_M_Color_1.jpg',
    },
    wrapperPrimaryColor: { type: 'string', default: '' },
    wrapperSecondaryColor: { type: 'string', default: '' },
  },
  init: function () {
    const { texturePath, wrapperPrimaryColor, wrapperSecondaryColor } = this.data;

    // Wait for model to load.
    this.el.addEventListener('model-loaded', () => {
      // Grab the mesh / scene.
      const obj = this.el.getObject3D('mesh');
      // Go over the submeshes and modify materials we want.
      console.log('wrapper object3D', this.el.object3D);
      obj.traverse((node) => {
        // TODO: check if possible to export ribbon material settings for roughness and metalness
        if (node.material) {
          console.log('wrapper', obj, node.material, node.name);
          //move this to json with settings
          if (node.material.name === 'mat_ribbon') {
            node.material.roughness = 0;
            node.material.metalness = 1.0;
          }
          if (node.material.name === 'mat_wrapper') {
            node.material.roughness = 0.5;
            node.material.metalness = 0.5;
          }
        }
        if (node.name === 'Treasure_Chest') {
          var tex = new THREE.TextureLoader().load(texturePath, (loadedTexture) => {
            node.material.map = loadedTexture;
            node.material.reflectivity = 1;
            node.material.skinning = true;
            node.material.clearcoat = 1;
            node.material.clearcoatRoughness = 0;

            node.material.needsUpdate = true;
            loadedTexture.wrapS = THREE.MirroredRepeatWrapping;
            loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
            loadedTexture.needsUpdate = true;
          });
        }
        if (
          node.name.indexOf(
            // name +
            '_0',
          ) !== -1 &&
          wrapperPrimaryColor !== ''
        ) {
          node.material.color.set(wrapperPrimaryColor);
        }
        if (
          node.name.indexOf(
            // name +
            '_1',
          ) === 7 &&
          wrapperSecondaryColor !== ''
        ) {
          node.material.color.set(wrapperSecondaryColor);
        }
      });
    });
  },
});
