import * as THREE from 'three';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('attach-glb-to-attacher', {
  schema: {
    glbId: {
      type: 'string',
    },
    attacher: { type: 'string', default: '' },
  },
  attacherObject: null,
  createCustomAndAttach: function () {
    const { glbId } = this.data;
    var sceneEl = document.querySelector('a-scene');
    var entityEl = document.createElement('a-entity');
    // Do `.setAttribute()`s to initialize the entity.

    entityEl.setAttribute('id', 'custom');
    entityEl.setAttribute('cubemap-realtime', true);
    entityEl.setAttribute('shadow', true);
    entityEl.setAttribute('remove-glb-lights', true);
    entityEl.setAttribute('make-materials-reflective', true);
    entityEl.setAttribute('gltf-model', '#' + glbId);
    sceneEl.appendChild(entityEl);
    console.log('attach-glb-to-attacher createCustomAndAttach', this.attacherObject, entityEl);
    entityEl.addEventListener('model-loaded', () => {
      console.log(
        'attach-glb-to-attacher loaded custom, attaching..',
        this.attacherObject,
        entityEl.object3D,
      );
      const attacherChildren = this.attacherObject.children;
      attacherChildren.forEach((child) => this.attacherObject.remove(child));
      this.attacherObject.add(entityEl.object3D);
    });
  },
  init: function () {
    const { glbId, attacher } = this.data;

    this.el.addEventListener('model-loaded', () => {
      const obj = this.el.object3D;
      console.log('attach-glb-to-attacher object3D', this.el.object3D, glbId, attacher);
      obj.traverse((node) => {
        // console.log('attach-glb-to-attacher', node.name);
        if (node.name === attacher) {
          this.attacherObject = node;
        }
      });
      if (!this.attacherObject) {
        console.error('glb model does not have an attacher called ' + attacher, obj);
      } else {
        this.createCustomAndAttach();
      }
    });
  },
});
