const xrLightSystem = {
  init() {
    this.intensity = 1;
    const startListen = () => {
      window.XR8.XrController.configure({ enableLighting: true });
      window.XR8.addCameraPipelineModule({
        name: 'xr-light',
        onUpdate: ({ processCpuResult }) => {
          if (
            processCpuResult.reality &&
            processCpuResult.reality.lighting &&
            processCpuResult.reality.lighting.exposure
          ) {
            this.intensity = 1 + processCpuResult.reality.lighting.exposure;
          }
        },
      });
    };
    window.XR8 ? startListen() : window.addEventListener('xrloaded', startListen);
  },
};
const xrLightComponent = {
  schema: {
    min: { type: 'int', default: 0 },
    max: { type: 'int', default: 2 },
  },
  tick() {
    const headlights = this.el.sceneEl.querySelectorAll('a-cone');
    let newIntensity = Math.max(this.data.min, Math.min(this.system.intensity, this.data.max));
    newIntensity = newIntensity < 0.03 ? -1 : newIntensity;

    // console.log(this.system.intensity, newIntensity);
    this.el.setAttribute('light', `intensity: ${newIntensity}`);
    if (!headlights.length) {
      return;
    }
    if (this.system.intensity < 0.5) {
      headlights[0].object3D.visible = true;
      headlights[1].object3D.visible = true;
    } else {
      headlights[0].object3D.visible = false;
      headlights[1].object3D.visible = false;
    }
  },
};
AFRAME.registerComponent('xr-light', xrLightComponent);
AFRAME.registerSystem('xr-light', xrLightSystem);
// export { xrLightComponent, xrLightSystem };
