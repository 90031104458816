import * as THREE from 'three';

const AFRAME = window.AFRAME;

AFRAME.registerComponent('content', {
  schema: {
    contentTextureUrl: {
      type: 'string',
      default: 'https://nudgear.s3.us-east-2.amazonaws.com/ArAssests/textures/trans.png',
    },
  },
  init: function () {
    const { contentTextureUrl } = this.data;
    // Wait for model to load.
    this.el.addEventListener('model-loaded', () => {
      // Grab the mesh / scene.
      const obj = this.el.getObject3D('mesh');
      // Go over the submeshes and modify materials we want.
      obj.traverse((node) => {
        if (node.isMesh) {
          var tex = new THREE.TextureLoader().load(contentTextureUrl);
          tex.wrapS = THREE.MirroredRepeatWrapping;
          tex.wrapT = THREE.MirroredRepeatWrapping;
          node.material.map = tex;
        }
      });
    });
  },
});
